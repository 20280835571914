import React, { useMemo } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Layout from "@/components/Layout";
import Seo from "@/components/Seo";
import Header, { NavigationItem } from "@/components/Header";
import Footer, { LinkItem } from "@/components/Footer";
import Media from "@/components/Media";
import ScrollAndFixed from "@/components/ScrollAndFixed";
import ProductReel, { mapDataToProducts } from "@/components/ProductReel";
import Heading from "@/components/Heading";
import { ProductFragment } from "@/fragments/product";
import notEmpty from "@/utils/not-empty";
import { AboutUsQuery } from "../../graphql-types";

interface Props {
  data: AboutUsQuery;
}

const AboutUsPage = ({ data }: Props) => {
  const {
    nodes: [collection],
  } = data.collection;
  const { headerLinks, footerLinks } = data.global || {};
  const reelProducts = useMemo(() => {
    const filteredProducts = collection?.products?.filter<ProductFragment>(notEmpty) || [];

    return mapDataToProducts(filteredProducts);
  }, [collection]);

  return (
    <Layout scroll="default" header={<Header layout="slim" navigation={headerLinks as NavigationItem[]} />}>
      <Seo title="About us" />
      <Layout.Section theme="white">
        <Media
          image={
            <StaticImage
              placeholder="blurred"
              src="../images/content/about_one.png"
              alt="landscape"
              width={3072}
              height={4608}
            />
          }
        >
          <div className="text-black">
            <Heading subtitle="About my Fairy tale">Neapolitan Demi-Couture</Heading>
            <p>
              The materiality of spirit and the spirituality of matter. The dreamlike inspiration of Saman Loira who
              puts Nature at the center together with the search for organic raw materials joins the beneficial
              properties of embroidered mineral stones, creating a synergy for clothes that are feelings and emotions.
              Combining the wonderful with the everyday, street style with haute couture, for him and for her in a game
              of reflections where men are capable of feelings, emancipating their feminine side and women to govern
              with determination.
            </p>
            <p className="mt-8">
              Saman Loira, embracing social issues such as diversity and inclusion with environmental issues such as
              sustainable urgency, wants to satisfy the most demanding requests: well-being, customization, comfort in
              wearability, sartorial craftsmanship, exclusivity and therefore uniqueness are the main focus of the
              creative direction of the brand.
            </p>
          </div>
        </Media>
      </Layout.Section>
      <Layout.Section theme="white">
        <Media
          layout="reverse"
          imageScrollType="default"
          image={
            <StaticImage
              placeholder="blurred"
              src="../images/content/about_two.png"
              alt="landscape"
              width={3072}
              height={4608}
            />
          }
        >
          <div className="text-black">
            <p>
              Saman is an ancient name of Persian origins whose meaning is in its entirety &quot;home&quot;. It embodies
              the poetics of refuge, well-being, feeling at ease, loved and protected. Loire, on the other hand, is the
              name of the longest river in the France, whose natural landscape is enhanced by the marvelous castles that
              run through it, attributing that dreamlike and almost surreal charm of the magic of these fairy-tale
              places.
            </p>
            <p className="mt-8">
              Saman Loira in this regard wants to be a place of refuge, a parallel limbo, where free from any
              superstructure social the individual expresses himself in his uniqueness experiencing the atmosphere of a
              realm inhabited by dreams.
            </p>
          </div>
        </Media>
      </Layout.Section>
      <ScrollAndFixed type="fixed">
        <StaticImage placeholder="blurred" src="../images/content/about_three.png" alt="shooting fall / winter" />
      </ScrollAndFixed>

      <ProductReel products={reelProducts} />

      <Footer links={footerLinks as LinkItem[]} />
    </Layout>
  );
};

export default AboutUsPage;

export const pageQuery = graphql`
  query AboutUs {
    collection: allShopifyCollection(limit: 1, filter: { title: { in: ["Top/Blouses SS23"] } }) {
      nodes {
        products {
          ...Product
        }
      }
    }
    global: dataJson {
      ...Global
    }
  }
`;
